import React, { useEffect } from "react"
import { graphql, Link, navigate } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Seo from "../components/seo"

import Layout from "../components/layout"
import InstaIcon from "../images/instagram-seeklogo.svg"

const PostPage = ({ data, pageContext }) => {
  // add keyboard nav listeners but do not override cmd+shortcuts
  const handleUserKeyUp = event => {
    if (event.key === "Meta" || event.key === "Alt") {
      localStorage.removeItem("cmdPressed") // cmd/alt key released
    }
  }

  const handleUserKeyPress = event => {
    if (event.key === "Meta" || event.key === "Alt") {
      localStorage.setItem("cmdPressed", true) // cmd/alt key pressed
    }

    if (!localStorage.getItem("cmdPressed")) {
      if (event.key === "ArrowRight" && pageContext.nextPath)
        navigate(pageContext.nextPath)

      if (event.key === "ArrowLeft" && pageContext.previousPath)
        navigate(pageContext.previousPath)
    }
  }

  useEffect(() => {
    window.addEventListener("keydown", handleUserKeyPress)
    window.addEventListener("keyup", handleUserKeyUp)

    return () => {
      window.removeEventListener("keydown", handleUserKeyPress)
      window.addEventListener("keyup", handleUserKeyUp)
    }
  })

  return (
    <Layout>
      <Seo
        title={data.allInstagramContent.nodes[0].caption || "Instagram Post"}
        socialImage={data.allInstagramContent.nodes[0].media_url}
      />
      <div className="post-grid">
        <div>
          {data.allInstagramContent.nodes[0].localImage.childImageSharp
            .gatsbyImageData.width >
          data.allInstagramContent.nodes[0].localImage.childImageSharp
            .gatsbyImageData.height ? (
            <GatsbyImage
              image={
                data.allInstagramContent.nodes[0].squareImage.childImageSharp
                  .gatsbyImageData
              }
              alt={
                data.allInstagramContent.nodes[0].caption || "Instagram Post"
              }
            />
          ) : (
            <GatsbyImage
              image={
                data.allInstagramContent.nodes[0].localImage.childImageSharp
                  .gatsbyImageData
              }
              alt={
                data.allInstagramContent.nodes[0].caption || "Instagram Post"
              }
            />
          )}
        </div>
        <div>
          <h1>{data.allInstagramContent.nodes[0].caption}</h1>

          <p style={{ marginLeft: "1rem" }}>
            <a
              href={data.allInstagramContent.nodes[0].permalink}
              style={{ color: "rgba(40, 40, 40, 0.8)" }}
            >
              <span className="instagram-icon">
                <InstaIcon />
              </span>
              Like and comment on Instagram
            </a>
          </p>
        </div>
      </div>

      <div className="paging">
        {pageContext.previousPath && (
          <Link
            className="previous"
            style={{ marginRight: ".5rem" }}
            to={pageContext.previousPath}
          >
            &lt; <span>Previous</span>
          </Link>
        )}
        {pageContext.nextPath && (
          <Link className="next" to={pageContext.nextPath}>
            <span>Next</span> &gt;
          </Link>
        )}
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($postId: String!) {
    allInstagramContent(
      filter: { id: { eq: $postId } }
      sort: { fields: timestamp, order: DESC }
    ) {
      nodes {
        id
        caption
        localImage {
          childImageSharp {
            gatsbyImageData(width: 768, placeholder: BLURRED)
          }
        }
        squareImage: localImage {
          childImageSharp {
            gatsbyImageData(
              width: 768
              height: 768
              placeholder: BLURRED
              layout: CONSTRAINED
              transformOptions: { cropFocus: CENTER }
            )
          }
        }
        permalink
        media_url
      }
    }
  }
`

export default PostPage
